#research-div {
  display: block;
  width: 75%;
}

.publication {
  display: flex;
  margin-bottom: 1em;
}

.year {
  width: 4em;
}

.publication p {
  margin: 0;
}

.title {
  font-weight: bold;
}

.location {
  font-style: italic;
  color: dimgrey;
}

.authors {
  color: dimgray;
}

.authors b {
  color: black;
  font-weight: normal;
}

.links a {
  font-size: 0.65em;
  color: var(--accent) !important;
}

@media only screen and (max-width: 1200px) {
  #research-div {
    width: 100%;
  }
 }