#menu-div {
  width: 25%;
}

#menu-div a {
  display: block;
  text-decoration: none;
  color: dimgrey;
  transition: color 200ms linear;
  margin-bottom: 10px;
  padding-left: 10px;
  margin-left: -10px;
}

#menu-div a:hover {
  color: black;
  transition: color 200ms linear;
}

.active-page {
  color: black !important;
  font-weight: bold;
  border-left: solid 1px var(--accent);
}

@media only screen and (max-width: 1200px) {
  #menu-div {
    width: 100%;
    display: flex;
    align-items: center;
  }
  #menu-div a {
    margin: 0px 10px;
    padding: 0;
  }
  .active-page {
    border: none;
    border-bottom: solid 1px var(--accent);
  }
  #menu-div h1 {
    /* width: 100%; */
    border-right: solid 1px var(--accent);
    padding-right: 30px;
    margin-right: 20px;
  }
 }

 @media only screen and (max-width: 610px) {
  #menu-div {
    justify-content: center;
  }
  #menu-div h1 {
    width: 100%;
    border: none;
    font-size: 1.2em;
  }
  #menu-div a {
    margin: 0;
    margin-left: 20px;
    font-size: 0.9em;
  }
 }

 @media only screen and (max-width: 540px) {
  #menu-div h1 {
    font-size: 1em;
  }
  #menu-div a {
    font-size: 0.8em;
    margin-left: 15px;
  }
 }

 @media only screen and (max-width: 450px) {
  #menu-div h1 {
    font-size: 0.9em;
    margin: 0;
    padding: 0;
  }
  #menu-div a {
    font-size: 0.7em;
    margin-left: 12px;
  }
 }

 @media only screen and (max-width: 335px) {
  #menu-div h1 {
    font-size: 0.8em;
    margin: 0;
    padding: 0;
  }
  #menu-div a {
    font-size: 0.65em;
    margin-left: 10px;
  }
 }

 @media only screen and (max-width: 300px) {
  #menu-div h1 {
    font-size: 0.75em;
  }
  #menu-div a {
    font-size: 0.6em;
    margin-left: 8px;
  }
 }